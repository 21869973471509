import React from 'react';
import { Container, List } from 'semantic-ui-react';

const Objectives = () => (
  <Container text>
    <p>
      The Objectives of Cross Currents International Ministries were formulated
      in 1968 and still reflect our present ministries.
    </p>
    <List relaxed as="ul" bulleted>
      <List.Item as="li">To explain and expound the Scriptures. </List.Item>
      <List.Item as="li">
        To depend on God’s enabling as we bear witness to lay people and church
        leaders alike of the sufficiency, vitality and the authority of the Word
        of God.
      </List.Item>
      <List.Item as="li">
        To fearlessly apply the Word of God to the contemporary scene and so
        prepare the way for the cleansing and revival of Christians as we
        anticipate the soon return of the Lord Jesus Christ in the air for His
        blood-bought redeemed Church.
      </List.Item>
      <List.Item as="li">
        To encourage men and women the world around, to pray for the
        intervention of God in the affairs of men, “For we wrestle not against
        flesh and blood, but against principalities, against powers, against the
        rulers of the darkness of this world, against spiritual wickedness in
        high places” Ephesians 6:12.
      </List.Item>
      <List.Item as="li">
        To emphasize the victory and the sole sufficiency of our Lord Jesus
        Christ, made personal and real by God’s provision for Christian
        living—the indwelling Holy Spirit.
      </List.Item>
      <List.Item as="li">
        To make such ministry regularly available the world around particularly
        where there is persecution of Christians without Church privilege.
      </List.Item>
      <List.Item as="li">
        To unite with and undergird the testimony of other believers who share
        such goals of seeing precious peoples converted to the Lord Jesus
        Christ, “Who hath delivered us from the power of darkness, and
        translated us into the kingdom of His dear Son: In Whom we have
        redemption through His blood, even the forgiveness of sins” Colossians
        1:13-14.
      </List.Item>
    </List>
  </Container>
);

export default Objectives;
