import React, { Component } from 'react';
import { Grid, Card, Pagination } from 'semantic-ui-react';
import { shape, arrayOf, string } from 'prop-types';

import PageMenu from '../PageMenu';
import PostItem from './PostItem';

const pageSize = 12;

class PostFeed extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: '', activePage: 1 };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.filterPosts = this.filterPosts.bind(this);
    this.paginatePosts = this.paginatePosts.bind(this);
  }

  handleSearchChange(e, data) {
    this.setState({ searchTerm: data.value });
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage });
  }

  filterPosts() {
    const { searchTerm } = this.state;
    const { posts, postType } = this.props;
    return posts
      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
      .filter(
        post =>
          post.Date &&
          post.Image &&
          post.PDF &&
          post.Type === postType &&
          post.Title.toUpperCase().indexOf(searchTerm.toUpperCase()) >= 0
      );
  }

  paginatePosts(posts) {
    const { activePage } = this.state;
    const pageIndex = activePage - 1;
    return posts.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
  }

  render() {
    const { match, postType } = this.props;
    const { activePage } = this.state;
    const posts = this.filterPosts();
    return (
      <Grid columns={1} stretched>
        <Grid.Column>
          <PageMenu
            headerName={`${postType}s`}
            handleSearchChange={this.handleSearchChange}
            placeholder="Filter Posts..."
          />
          <Card.Group centered>
            {this.paginatePosts(posts).map(post => (
              <PostItem post={post} url={match.url} />
            ))}
          </Card.Group>
          <Pagination
            pointing
            secondary
            activePage={activePage}
            onPageChange={this.handlePaginationChange}
            totalPages={Math.ceil(posts.length / pageSize)}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

PostFeed.propTypes = {
  match: shape().isRequired,
  posts: arrayOf(shape()).isRequired,
  postType: string.isRequired
};

export default PostFeed;
