import React from 'react';
import { Card, Image, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { arrayOf, shape, string } from 'prop-types';

import addEllipsis from './addEllipsis';

const MinistryFeed = ({ ministries, terms }) =>
  ministries.length > 0 ? (
    ministries.map(ministry => (
      <Card
        key={ministry.id}
        href={`https://www.twr360.org/ministry/${ministry.id}`}
      >
        <Image fluid src={ministry.image} alt={`${ministry.name} logo`} />
        <Card.Content>
          <Card.Header>{ministry.name}</Card.Header>
          <Card.Description>
            {addEllipsis(ministry.description)}
          </Card.Description>
        </Card.Content>
      </Card>
    ))
  ) : (
    <Message>
      <Message.Header>{terms[5]}</Message.Header>
      <Message.Content>
        <p>{terms[6]}</p>
        <Link to="/languages">{terms[2]}</Link>
      </Message.Content>
    </Message>
  );

MinistryFeed.propTypes = {
  ministries: arrayOf(shape).isRequired,
  terms: arrayOf(string).isRequired
};

export default MinistryFeed;
