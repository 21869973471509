import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const Testimonies = () => (
  <Container text>
    <Header as="h3">Washington</Header>
    <p>
      &quot;Do you remember me?&quot; said Washington, a very happy Kenyan man.
      &quot;We&apos;ve met before&quot; I responded. I did not remember just
      where or when it was. &quot;Yes, the last time we met two years ago you
      gave me a Bible&quot;, he gleefully responded.
    </p>

    <p>
      Now it was my time to ask a question. &quot;Oh -- what happened to you
      after you received your Bible?&quot; I said. &quot;Well, let me put it
      this way.&quot; my new friend responded, &quot;I took my Bible home and
      began to read it to my family. And since we began to read the Bible
      together, Jesus has come to live in our house! And because Jesus has come
      to live in our house, we have been married in a proper way.&quot;
    </p>

    <p>
      Yes, the Bible does tell us that there will be a famine of the Word of God
      in the last days, and that was never more true than it is for multiplied
      millions of desperately poor Kenyans who can read English but have no
      Bible to read.
    </p>

    <Header as="h3">Shadrach</Header>

    <p>
      Let me tell you about Shadrach! Shadrach lives in Kenya and as a boy was
      afflicted with polio. When Dorothy and I met him his legs were spindly
      as&nbsp; match sticks as his torso sat at the edge of a dusty pavement.
      Hung over his back was a torn small backpack in which all his worldly
      possessions were stored. He showed them to us -- a few gospel tracts, a
      small writing pad, a pencil and a few pieces of string.
    </p>

    <p>
      Shadrach&apos;s hands were the only means of shuffling his torso down the
      pavement. Dorothy noticed that his bloodshot eyes were the result of his
      constantly rubbing them with his dusty hands. At Dorothy&apos;s suggestion
      I hastened to get an eye wash only to return to find Dorothy sitting on a
      fragile box beside him. As she washed his eyes we soon learned that
      Shadrach was our brother in Christ who truly loved the Lord Jesus.
    </p>

    <p>
      We gave Shadrach a few Kenyan shillings as he told us he never begged but
      trusted the Lord Jesus to provide his meager daily sustenance of food. He
      was very grateful for the few shillings! But when we gave Shadrach a
      Bible, he was ecstatically excited. He caressed it and kissed it as a
      mother would her child!
    </p>

    <p>
      The last but one time that we saw Shadrach, Dorothy and I prayed for him:
      &quot;Lord Jesus You know Shadrach needs a new shirt and a new pair of
      trousers.&quot; His trousers and shirt were torn and dirty to the point of
      being unrespectable. &quot;And you know dear Lord, I could provide
      Shadrach with this need of his, but if I were to do that, I would become
      his source of supply and when Dorothy and I leave tomorrow, his source of
      supply will go with us. So I pray in Jesus&apos; name, that You will give
      Shadrach a new shirt and pair of trousers in such a way that he knows it
      comes from You and that You will continue to supply his frugal needs even
      when Dorothy and I have to leave.&quot;
    </p>

    <p>
      We saw Shadrach just one more time as he had propelled his torso down the
      street to the spot on the sidewalk where we first met him. Excitedly, he
      gesticulated for us to come and speak with him. Pulling his backpack from
      his shoulder, he opened it to reveal a brand new shirt and a brand new
      pair of trousers!
    </p>

    <p>
      &quot;I don&apos;t know who it was&quot; said Shadrach, but as a stranger
      passed by -- it might have been a man or an angel, I don&apos;t know, but
      look what he dropped in my lap.&quot; And then he proudly displayed his
      new shirt and trousers.
    </p>

    <p>
      Yes, Shadrach was very happy that he would now be respectably dressed. But
      his joy over the shirt and trousers was nothing to be compared with the
      ecstatic joy that he displayed when he received his very own Bible.
    </p>

    <Header as="h3">Bibles For Africa</Header>

    <p>
      Draught was prevalent throughout Kenya as Dorothy and I arrived to teach
      at a Pastors Conference. We were accompanied by a team of American
      Christians who would drill water wells. We were privileged to teach 100
      pastors who would be gathering. Many of the 100 pastors were recent
      converts and God had blessed their testimonies resulting in new churches
      being birthed.
    </p>

    <p>
      A hand full of pastors had walked for 17 hours in the Equatorial sun and
      the parched landscape to have the opportunity of being taught the Word of
      God.
    </p>

    <p>
      But, can you imagine it, the majority of these precious pastors did not
      have a Bible?&nbsp; What a privilege it was to give each pastor a Bible.
      We then began to teach: &quot;It&apos;s not enough to have a Bible in your
      hand, it&apos;s not enough to read it and get it in your head, I want to
      tell you how to read it and get it from your hand to your head to your
      heart.&quot;&nbsp; Although the pastors were excited to have a stodgy
      plate of carbohydrates, over which was poured a thin pretense of gravy,
      there is no way to describe their absolute joy of receiving the very&nbsp;
      &quot;bread of Life&quot; -- a copy of&nbsp; the Word of God for
      themselves. Neither is there a way to describe the absolute dearth of
      Bibles in Kenya where it is commonly known that &quot;the church is a 100
      miles wide and an inch deep&quot;!
    </p>

    <Header as="h3">The Stick Lady on the Ground</Header>

    <p>
      On the way to a Pastor&apos;s Conference where we were to teach 100
      country pastors, my wife, because of intense spinal problems, was unable
      to traverse the country trail in a vehicle. So, with our translator, we
      walked together down a trail through the forest for the last 3 to 4 miles.
      On the horizon we noticed what appeared to be a moving bundle of&nbsp;
      wooden sticks. As it approached we saw this heavy burden was carried on
      the bent-over back of a fragile African lady. They call them the
      &quot;stick ladies&quot;! My wife said, through our translator, &quot;Why
      don&apos;t you lay down your heavy burden and we will talk
      awhile.&quot;&nbsp; The stick lady fell backwards with her back falling on
      the bundle of sticks in order to free herself. Then Dorothy took one of
      the sticks and said &quot;I want to tell you about the One who made the
      forest from which this stick came&quot;, and as she talked other stick
      ladies came down the trail, dropping their heavy burdens.
    </p>

    <p>
      One of the team, who was there to drill water wells, was sent to find some
      food and produced on a tray but a few slices of bread commanding:
      &quot;Divide the slices in half as there is not enough to go around for
      everybody and only the most hungry can be given bread!&quot; Dorothy
      looked at the starving bodies who were suffering from a lack of food
      caused by a severe draught. Knowing that the stick ladies could not
      understand her English, Dorothy said &quot;I believe that the Lord will
      make provision to feed these ladies even if He has to turn the tray into a
      loaf of bread.&quot;. Reluctantly, the ladies pushed each other forward
      and hesitantly came for the bread until Dorothy was handing out the last
      piece. Suddenly, the supplier of the bread arrived with plenty for all and
      everybody had some physical nourishment. Talk about feeding the 5000!
    </p>

    <Header as="h3">The Stick Ladies</Header>

    <p>
      Each evening at the same trail, increasing numbers of stick ladies
      gathered as Dorothy expounded the Word of God for 1 to 2 hours. By the
      last evening between 4-500 ladies gathered on a nearby hillside. We never
      did get to the ladies meeting. The ladies came to the stick ladies
      meeting!
    </p>

    <p>
      We will never forget the last night when with ecstatic joy, the stick
      ladies responded to the Lord Jesus and received Him into their hearts and
      lives and began to sing in African harmony, praises to their new found
      Savior, and thanksgiving for the precious Bibles which we had been able to
      place in their hands.
    </p>

    <p>
      As the stick ladies disappeared into the African sunset, those ladies who
      were nicely dressed&nbsp; for the planned pastor&apos;s wives
      meeting,&nbsp; helped lift the bundles of sticks back onto the stick
      ladies shoulders and assisted them on their way back to their villages. We
      said: &quot;Now we have seen living example of what it means to bear one
      another&apos;s burdens&quot;.
    </p>

    <p>
      There are millions of people who long for the Word of God Who is the Bread
      of Life. The Lord alone will continue to feed their souls until they see
      Jesus face to face!
    </p>
  </Container>
);

export default Testimonies;
