import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import './App.css';
import getPosts from './components/getPosts';
import Navigation from './components/Navigation';
import Routes from './Routes';

const initialState = { hasError: false, error: '', info: '' };

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...Object.assign(initialState, window.pageData),
      posts: [],
      loadingPosts: true
    };
  }

  componentWillMount() {
    getPosts().then(posts => this.setState({ posts, loadingPosts: false }));
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error, info, pagePath, languageId, terms } = this.state;
    return hasError ? (
      <>
        <p>{String(error)}</p>
        <p>{String(info)}</p>
      </>
    ) : (
      <BrowserRouter basename={pagePath}>
        <>
          <Navigation languageId={languageId} terms={terms} />
          <Container>
            <Routes {...this.state} />
          </Container>
        </>
      </BrowserRouter>
    );
  }
}

export default App;
