import React from 'react';
import { Container, Divider, List } from 'semantic-ui-react';

const History = () => (
  <Container text>
    <p>
      Even though Cross Current International Ministries (CCIM) was not
      officially established until 1980, in essence, CCIM was birthed 22 years
      earlier at the wedding of Richard and Dorothy Bennett in June of 1958. Dr.
      Stephen Olford conducted the service.
    </p>

    <p>
      A look back at their 60 plus years of their ministry together, can
      certainly be traced to the day they recited their wedding vows and
      committed themselves together to serve the Lord in full time ministry. In
      retrospect, Pastor Olford’s message entitled,
      <em> “Together in Love, Together in Life, Together in Labor”</em>, was
      truly a confirmation of God’s calling on their lives to
      <em>“Preach the Word”</em>. The Bennett’s have remained faithful to that
      calling for over six decades!
    </p>

    <Divider horizontal>1958-1969</Divider>

    <p>
      In the early years of their united ministry in England, Dr. and Mrs.
      Richard A. Bennett:
    </p>

    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        Pioneered Home Evangelism in connection with Church crusades throughout
        the UK.
      </List.Item>
      <List.Item as="li">
        Taught regularly at Capernwray Bible School.
      </List.Item>
      <List.Item as="li">
        Taught at Evangelical Students Missionary Association conferences and
        Bible school ministries across Europe including to the persecuted Church
        ‘behind the Iron Curtain’.
      </List.Item>
      <List.Item as="li">
        Ministry over Trans World Radio/Europe commenced.
      </List.Item>
      <List.Item as="li">
        Missionary Radio (MRI) was incorporated in the state of New Jersey,
        November 17th 1969.
      </List.Item>
    </List>

    <Divider horizontal>1970-1982</Divider>

    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        Missionary Radio Incorporated carries the Bennett’s ministry of the Word
        to people on five continents via missionary radio broadcasts.
      </List.Item>
      <List.Item as="li">
        Missionaries, national pastors and laymen wrote for spiritual help and
        requested follow-up literature. In just one twelve month period, 5000
        spiritual inquiries from 47 different countries were answered by hand
        from just one weekly broadcast.
      </List.Item>
      <List.Item as="li">
        1978 Richard authors
        <em> Food for Faith </em>
        (formerly known as
        <em> Bible Before Breakfast</em>
        ).
      </List.Item>
      <List.Item as="li">
        Overseas missionary journeys become more frequent and prolonged.
      </List.Item>
      <List.Item as="li">
        Missionary Radio Inc. phases into a broader overseas ministry and so the
        name is changed to Cross Current International Ministries (CCIM) on
        November 3rd, 1980.
      </List.Item>
    </List>

    <Divider horizontal>1983-1987</Divider>

    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        1983 Richard authors
        <em> Your Quest for God </em>
        which to date has been translated and released in 60+ languages.
      </List.Item>
      <List.Item as="li">
        God’s hand of blessing is evident in unprecedented ways as CCIM
        continues to be a catalyst ministry in numerous countries emphasizing
        the priorities of evangelism, supplicatory prayer, revival, and solid
        Bible teaching with biblical Gospel presentations.
      </List.Item>
    </List>

    <Divider horizontal>1988-Present</Divider>

    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        Far-flung missionary journeys increase due to the wide response of the
        radio ministry. This encouraged national pastors, missionaries and
        national evangelists. Increasingly, CCIM’s evangelistic ‘tools’ were
        provided for nationals to undergird their own ministries in India,
        Africa, the Far East, Europe and the Middle East.
      </List.Item>
      <List.Item as="li">
        <em>Your Quest for God </em>
        and
        <em> Food for Faith </em>
        continues to this day to be an ‘evangelistic tool’ and a major
        discipleship resource around the world via broadcasts, hard copy and
        Internet. The Bennett’s prayerfully seek God’s direction as the Holy
        Spirit leads in using these and other of their books in an audio format
        for radio listener’s follow-up and ministry in multiple languages to the
        persecuted Church.
      </List.Item>
      <List.Item as="li">
        Richard writes
        <em> Hope for the Hopeless </em>
        an AIDS tract, but increasingly requested as a Salvation tract. This
        much-requested tract, now in 63 languages, is translated and distributed
        in multiple languages and is already widely used in printed format,
        Internet and in audio format.
      </List.Item>
      <List.Item as="li">
        <em>Dorothy’s Daily Devotional, </em>
        is produced and broadcast in 13 languages for the three-year cycle of
        the 780 programs. Great potential exists to translate these inspiring
        devotionals into other languages for airing on the radio and by the
        Internet.
      </List.Item>
      <List.Item as="li">
        Expansion of CCIM literature in Arabic and Middle East languages
        continues by radio and Internet.
      </List.Item>
      <List.Item as="li">
        CCIM, in cooperation with Trans World Radio, launches an innovative ESL
        (English as a second language) version of
        <em> Your Quest for God </em>
        and
        <em> Food for Faith </em>
        for use on the internet ministry to China.
      </List.Item>
      <List.Item as="li">
        CCIM enters the world of the Internet with the development and
        deployment of its own website.
      </List.Item>
      <List.Item as="li">
        Ministry initiative to systematically translate and record audio
        versions of
        <em> Your Quest for God</em>,<em> Food for Faith</em>,
        <em> Biblical Reality</em>,<em> Forward in Faith </em>
        and
        <em> Hope for the Hopeless </em>
        into various languages for deployment on a digital platform developed by
        Trans World Radio.
      </List.Item>
    </List>

    <p>
      CCIM’s influence and ministry impact includes churches, educational
      institutions, and Christian organizations around the world. Below is a
      partial list of requests for preaching/teaching opportunities, of the
      Bennett’s down through the years.
    </p>

    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        Trans World Radio (Swaziland, South Africa, Monte Carlo, Madison, New
        Jersey and Cary, North Carolina)
      </List.Item>
      <List.Item as="li">
        Far East Broadcasting Corporation: Richard’s broadcasts from Manila in
        English to all India and, an FEBC Conference for Vietnamese workers at
        the fall of Saigon
      </List.Item>
      <List.Item as="li">
        Torchbearers at Capernwray Hall, England as Conference Speaker and Bible
        School Teacher
      </List.Item>
      <List.Item as="li">
        Prairie Bible Institute: Graduation Speaker and Mission Conferences
      </List.Item>
      <List.Item as="li">
        Briercrest Bible Institute: Graduation Speaker, Mission Conferences and
        Ministry to Students
      </List.Item>
      <List.Item as="li">
        Pambrum Bible College: Mission Conferences and Ministry to Students
      </List.Item>
      <List.Item as="li">Toronto Bible College: Ministry to Students</List.Item>
      <List.Item as="li">
        Christian and Missionary Alliance: Ministry to students at Regina
        Training College and at the Annual Pastor&apos;s Convention
      </List.Item>
      <List.Item as="li">Eastern European Mission</List.Item>
      <List.Item as="li">Greater European Mission</List.Item>
      <List.Item as="li">German Intermissions</List.Item>
      <List.Item as="li">Italian Intermissions</List.Item>
      <List.Item as="li">
        Torrey Memorial Conferences, Southern California
      </List.Item>
      <List.Item as="li">
        Word of Life Conferences, Schroon Lake, New York
      </List.Item>
      <List.Item as="li">Navigator Conferences</List.Item>
      <List.Item as="li">
        Campus Crusade at headquarters and elsewhere
      </List.Item>
      <List.Item as="li">
        Inter Varsity Fellowship in various countries
      </List.Item>
      <List.Item as="li">
        Open Doors with Brother Andrew at Leadership Conference and elsewhere
      </List.Item>
      <List.Item as="li">
        The Faith Mission (Northern Ireland; Southern Ireland; Scotland)
      </List.Item>
      <List.Item as="li">International Hospital Christian Fellowship</List.Item>
      <List.Item as="li">
        Evangelische Omroep, (Hilversum, Holland national television and radio
        ministries)
      </List.Item>
      <List.Item as="li">Operation Mobilization</List.Item>
      <List.Item as="li">Youth with a Mission</List.Item>
      <List.Item as="li">Christian Resource Management</List.Item>
      <List.Item as="li">Wycliffe Associates</List.Item>
      <List.Item as="li">Youth for Christ</List.Item>
      <List.Item as="li">National Young Life</List.Item>
      <List.Item as="li">
        Officers Christian Fellowship (Headquarters, Colorado: West Point; Pearl
        Harbor; Eglin Air Force Base, N.A.T.O. Headquarters in Europe and many
        other military bases)
      </List.Item>
      <List.Item as="li">
        Keswick Ministries (10 South Africa cities; Jamaica; Canada; USA and
        others)
      </List.Item>
      <List.Item as="li">Revival Challenge (South Africa)</List.Item>
      <List.Item as="li">Lighthouse for Christ (Mombasa, Kenya)</List.Item>
      <List.Item as="li">
        Youth Evangelism Ministry and Missionary Conferences (Mombasa, Kenya)
      </List.Item>
      <List.Item as="li">
        The American Mission for opening Churches; Word of Life, USA and UK
      </List.Item>
      <List.Item as="li">
        Ministry to Middle East Christians (Cyprus; Egypt and elsewhere)
      </List.Item>
      <List.Item as="li">
        Also, multiple Bible Colleges and Seminaries in England, Germany,
        Switzerland etc.
      </List.Item>
      <List.Item as="li">
        Dr. Martin Lloyd Jones: Ministry to his various Pastors Fellowships in
        Wales, UK
      </List.Item>
      <List.Item as="li">
        Multiple Churches: Throughout Europe, North America and elsewhere. Plus
        a Pastorate in UK and Rhode Island USA
      </List.Item>
    </List>

    <p>
      As the Bennett’s complete the journey the Lord has called them to, there
      is a great sense of urgency that time is short, and that they must do all
      they can through the leading and empowering of the Holy Spirit to use
      media to its fullest potential to advance the Gospel. CCIM and TWR signed
      a “Model Agreement” to ensure a lasting legacy is continued of the
      Bennett’s ‘Bible-centered’ ministries reaching so many lives around the
      world.
    </p>
  </Container>
);

export default History;
