import React from 'react';
import { Menu, Input } from 'semantic-ui-react';
import { string, func } from 'prop-types';

import PrayerLetterSubscription from './PrayerLetterSubscription';

const PageMenu = ({ headerName, handleSearchChange, placeholder }) => (
  <Menu secondary pointing stackable>
    <Menu.Item header as="h2">
      {headerName}
    </Menu.Item>
    {headerName === 'Prayer Letters' && (
      <Menu.Item position="right">
        <PrayerLetterSubscription />
      </Menu.Item>
    )}
    <Menu.Item position="right">
      <Input
        onChange={(e, data) => handleSearchChange(e, data)}
        icon="search"
        placeholder={placeholder}
      />
    </Menu.Item>
  </Menu>
);

PageMenu.propTypes = {
  headerName: string.isRequired,
  handleSearchChange: func.isRequired,
  placeholder: string.isRequired
};

export default PageMenu;
