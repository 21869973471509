import React, { Component } from 'react';
import { Container, Loader, Card, Image } from 'semantic-ui-react';
import { Link, Route } from 'react-router-dom';
import { string, shape } from 'prop-types';

import fetchvideoData from './fetchVideoData';
import urlify from '../../urlify';
import VideoModal from './VideoModal';

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingVideos: true,
      videos: []
    };
  }

  componentWillMount() {
    const { playlist, ytKey } = this.props;
    fetchvideoData(playlist, ytKey)
      .then(res =>
        this.setState({ videos: res.data.items, loadingVideos: false })
      )
      .catch(err => console.log(err));
  }

  render() {
    const { match } = this.props;
    const { loadingVideos, videos } = this.state;
    return (
      <>
        <Container>
          <Loader active={loadingVideos} />
          <Card.Group centered>
            {videos.map(video => (
              <Card
                key={video.id}
                as={Link}
                to={`${match.path}/${urlify(video.snippet.title)}`}
              >
                <Image
                  src={video.snippet.thumbnails.medium.url}
                  alt={video.snippet.title}
                />
                <Card.Content>
                  <Card.Header>{video.snippet.title}</Card.Header>
                  <Card.Description>
                    {video.snippet.description}
                  </Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Container>
        <Route
          path={`${match.path}/:videoName`}
          render={props => <VideoModal {...props} videos={videos} />}
        />
      </>
    );
  }
}

Videos.propTypes = {
  ytKey: string.isRequired,
  playlist: string.isRequired,
  match: shape().isRequired
};

export default Videos;
