import React from 'react';
import { Grid } from 'semantic-ui-react';

const LanguageList = ({ languages, pagePath }) =>
  languages.map(language => (
    <Grid.Column as="li" key={language.id}>
      <a
        href={
          language.active
            ? `${pagePath}/ministries?lang=${language.languageId}`
            : `http://old.ccim-media.com/media/?language_id=${language.languageId}`
        }
      >
        {`${language.localName} (${language.name})`}
      </a>
    </Grid.Column>
  ));

export default LanguageList;
