import React from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink, Route, Redirect } from 'react-router-dom';
import { shape, string } from 'prop-types';

import Name from './Name';
import History from './History';
import Objectives from './Objectives';
import ArticlesOfFaith from './ArticlesOfFaith';
import Testimonies from './Testimonies';
import Videos from './Videos';

const About = ({ match, ytKey, ytPlaylistId }) => (
  <>
    <Menu secondary pointing stackable>
      <Menu.Item header as="h2">
        <NavLink exact to={match.url} activeClassName="selected">
          About
        </NavLink>
      </Menu.Item>
      <Menu.Item header as="h2">
        <NavLink
          exact
          to={`${match.url}/objectives`}
          activeClassName="selected"
        >
          Objectives
        </NavLink>
      </Menu.Item>
      <Menu.Item header as="h2">
        <NavLink
          exact
          to={`${match.url}/articles-of-faith`}
          activeClassName="selected"
        >
          Articles Of Faith
        </NavLink>
      </Menu.Item>
      <Menu.Item header as="h2">
        <NavLink to={`${match.url}/history`} activeClassName="selected">
          History
        </NavLink>
      </Menu.Item>
      <Menu.Item header as="h2">
        <NavLink to={`${match.url}/testimonies`} activeClassName="selected">
          Testimonies
        </NavLink>
      </Menu.Item>
      <Menu.Item header as="h2">
        <NavLink to={`${match.url}/videos`} activeClassName="selected">
          Videos
        </NavLink>
      </Menu.Item>
    </Menu>

    <Route exact path={match.path} render={Name} />
    <Route path={`${match.path}/history`} render={History} />
    <Route path={`${match.path}/objectives`} render={Objectives} />
    <Route path={`${match.path}/articles-of-faith`} render={ArticlesOfFaith} />
    <Route path={`${match.path}/testimonies`} render={Testimonies} />
    <Route
      path={`${match.path}/videos`}
      render={props => (
        <Videos ytKey={ytKey} playlist={ytPlaylistId} {...props} />
      )}
    />
    <Redirect path="*" to={match.path} />
  </>
);

About.propTypes = {
  match: shape().isRequired,
  ytKey: string.isRequired,
  ytPlaylistId: string.isRequired
};

export default About;
