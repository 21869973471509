import React from 'react';
import { Container, List } from 'semantic-ui-react';

const ArticlesOfFaith = () => (
  <Container text>
    <List relaxed as="ul" bulleted>
      <List.Item as="li">
        We believe in one God, eternally existent as God the Father, God the Son
        and God, the Holy Spirit.
      </List.Item>
      <List.Item as="li">
        We believe that the Bible, composed of the Old and New Testaments, is
        God's inspired and infallible Word, and is the supreme standard and
        final authority of all conduct, faith and doctrine.
      </List.Item>
      <List.Item as="li">
        We believe in the deity of the Lord Jesus Christ, in His virgin birth,
        in His sinless life, in His miracles, in His vicarious and atoning
        death, in His bodily resurrection, in His ascension to the right hand of
        the Father, and in His pretribulation personal return in the air for His
        Church and to return with them seven years later in power and glory at
        His Second Coming for His millennial reign on earth.
      </List.Item>
      <List.Item as="li">
        We believe that man was created in the image of God, but by willful
        transgression became sinful and is justly under the condemnation and
        wrath of Almighty God.
      </List.Item>
      <List.Item as="li">
        We believe that the only salvation from this guilt and condemnation is
        through faith in the righteousness and atonement of the Lord Jesus
        Christ, and that this salvation is the free gift of God's love and
        grace.
      </List.Item>
      <List.Item as="li">
        We believe in the personality of the Holy Spirit and that His ministry
        is to reveal Christ to men, to convict of sin, to regenerate repentant
        sinners and, by His presence and power, to sanctify the lives of the
        redeemed.
      </List.Item>
      <List.Item as="li">
        We believe it is a privilege and responsibility of every Christian to be
        a witness unto Him. The Lord Jesus is the true soul winner and lives in
        the Christian by the power of the Holy Spirit. His activity of seeking
        and saving through a Christian is a normal and continual Christian
        experience.
      </List.Item>
      <List.Item as="li">
        We believe resources for Christian living are available in an
        understanding of our identification with Christ in His death, burial and
        resurrection and in the provision of Christ's indwelling. Normal
        Christian living is expressed in our Lord's words when He said, 'I am
        come that they might have life, and that they might have it more
        abundantly.' John 10:10.
      </List.Item>
      <List.Item as="li">
        We believe that a New Testament Church is a body of believers,
        associated for worship, service and the spread of the Gospel of the
        grace of God to all the world.
      </List.Item>
      <List.Item as="li">
        We believe that there will be a resurrection of the just and the unjust;
        the just, having been redeemed by the shed Blood of the Lord Jesus, to
        be with Him throughout eternity in glory; the unjust, having died
        impenitent and unreconciled to God, to eternal condemnation in Hell.
      </List.Item>
    </List>
  </Container>
);

export default ArticlesOfFaith;
