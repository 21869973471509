import React from 'react';
import { Route } from 'react-router-dom';
import { shape, arrayOf, string, bool } from 'prop-types';

import PostFeed from './PostFeed';
import Post from './Post';

const Posts = ({ match, posts, postType, loadingPosts }) => (
  <>
    <Route
      exact
      path={match.path}
      render={props => (
        <PostFeed {...props} posts={posts} postType={postType} />
      )}
    />
    <Route
      path={`${match.path}/:PostName`}
      render={props => (
        <Post
          {...props}
          posts={posts}
          loadingPosts={loadingPosts}
          postType={postType}
        />
      )}
    />
  </>
);

Posts.propTypes = {
  match: shape().isRequired,
  posts: arrayOf(shape()).isRequired,
  postType: string.isRequired,
  loadingPosts: bool.isRequired
};

export default Posts;
