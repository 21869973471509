const addEllipsis = text =>
  text.length > 320
    ? `${text
        .slice(0, 320)
        .split(' ')
        .slice(0, -1)
        .join(' ')} ...`
    : text;

export default addEllipsis;
