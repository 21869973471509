import React from 'react';

const NotFound = () => (
  <div>
    <p>Couldn&apos;t find that content</p>
    <a href="/">Back to listing</a>
  </div>
);

export default NotFound;
