import React from 'react';
import {
  Loader,
  Segment,
  Grid,
  Header,
  Button,
  Image
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { shape, arrayOf, bool, string } from 'prop-types';

import PrayerLetterSubscription from '../PrayerLetterSubscription';
import urlify from '../urlify';

const filterPosts = function filterPostTypeAndSortByDate(posts, postName) {
  return posts.filter(post => urlify(post.Title) === postName)[0];
};

const Post = ({ match, posts, loadingPosts, postType }) => {
  const post = filterPosts(posts, match.params.PostName);
  return (
    <>
      <Loader active={loadingPosts} />
      {post && (
        <Segment padded="very">
          <Grid columns={3} stackable>
            <Grid.Column>
              <Button
                as={Link}
                to={postType === 'Article' ? '/articles' : '/prayer-letters'}
                content="Back"
                icon="chevron left"
                labelPosition="left"
              />
            </Grid.Column>
            <Grid.Column>
              {post.Type === 'Prayer Letter' && <PrayerLetterSubscription />}
            </Grid.Column>
            <Grid.Column>
              <Button
                as="a"
                href={post.PDF.url}
                download
                target="_blank"
                content="Download"
                icon="download"
                style={{ float: 'right' }}
              />
            </Grid.Column>
          </Grid>

          <Header dividing as="h2">
            {post.Title}
          </Header>

          <Image
            style={{ float: 'right', margin: '0 0 1.5em 1.5em' }}
            src={post.Image.url}
          />
          <ReactMarkdown source={post.Body} />
        </Segment>
      )}
    </>
  );
};

Post.propTypes = {
  match: shape().isRequired,
  posts: arrayOf(shape()).isRequired,
  loadingPosts: bool.isRequired,
  postType: string.isRequired
};

export default Post;
