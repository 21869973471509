import React, { Component } from 'react';
import { Card, Loader, Message } from 'semantic-ui-react';
import { arrayOf, string, number } from 'prop-types';

import getMinistries from './getMinistries';
import PageMenu from '../PageMenu';
import MinistryFeed from './MinistryFeed';

class Ministries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      ministries: [],
      loading: true,
      error: false
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { languageId, ministries } = this.props;
    getMinistries(ministries, languageId).then(res => {
      if (!(res instanceof Error)) {
        this.setState({ ministries: res, loading: false });
      } else {
        this.setState({ error: true, loading: false });
      }
    });
  }

  handleSearchChange(e, data) {
    this.setState({ searchTerm: data.value });
  }

  render() {
    const { searchTerm, ministries, loading, error } = this.state;
    const { terms } = this.props;
    const filteredMinistries = ministries.filter(
      ministry =>
        `${ministry.name.toUpperCase()}`.indexOf(searchTerm.toUpperCase()) >= 0
    );
    return (
      <>
        <PageMenu
          headerName={terms[1]}
          handleSearchChange={this.handleSearchChange}
          placeholder={terms[3]}
        />
        {!error ? (
          <Card.Group centered>
            <Loader active={loading} />
            {!loading && (
              <MinistryFeed ministries={filteredMinistries} terms={terms} />
            )}
          </Card.Group>
        ) : (
          <Message negative>{terms[4]}</Message>
        )}
      </>
    );
  }
}

Ministries.propTypes = {
  terms: arrayOf(string).isRequired,
  ministries: arrayOf(number).isRequired,
  languageId: number.isRequired
};

export default Ministries;
