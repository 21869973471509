import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { shape, string } from 'prop-types';

import urlify from '../urlify';

const PostItem = ({ post, url }) => (
  <Card key={post.id} as={Link} to={`${url}/${urlify(post.Title)}`}>
    <Image src={post.Image.url} alt={`${post.Title} logo`} />
    <Card.Content>
      <Card.Header>{post.Title}</Card.Header>
      <Card.Meta>
        {new Date(post.Date).toLocaleDateString('default', {
          timeZone: 'UTC'
        })}
      </Card.Meta>
    </Card.Content>
  </Card>
);

PostItem.propTypes = {
  post: shape().isRequired,
  url: string.isRequired
};

export default PostItem;
