import React from 'react';
import { Modal } from 'semantic-ui-react';
import { arrayOf, shape } from 'prop-types';

import urlify from '../../urlify';

const VideoModal = ({ videos, match, history }) => {
  const video = videos.filter(
    vid => urlify(vid.snippet.title) === match.params.videoName
  )[0];
  const src = `https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?autoplay=1&rel=0&modestbranding=0`;
  return video ? (
    <Modal open closeIcon onClose={() => history.push('/about/videos')}>
      <Modal.Header>{video.snippet.title}</Modal.Header>
      <Modal.Content>
        <div className="player-container">
          <iframe
            className="player"
            title={`vid-${video.snippet.resourceId.videoId}`}
            type="text/html"
            width="100%"
            height="100%"
            src={src}
            frameBorder="0"
          />
        </div>
      </Modal.Content>
    </Modal>
  ) : null;
};

VideoModal.propTypes = {
  videos: arrayOf(shape()).isRequired,
  match: shape().isRequired,
  history: shape().isRequired
};

export default VideoModal;
