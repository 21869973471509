import React from 'react';
import { Button } from 'semantic-ui-react';

const PrayerLetterSubscription = () => (
  <Button
    basic
    as="a"
    href="https://www.twr360.org/ccim-letter-signup"
    content="Subscribe to Prayer Letter"
    icon="mail"
  />
);

export default PrayerLetterSubscription;
