import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Ministries from '../components/Ministries';
import Posts from '../components/Posts';
import About from '../components/About';
import Languages from '../components/Languages';
import NotFound from './NotFound';

const Routes = props => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="ministries" />} />
    <Route
      path="/ministries"
      render={subProps => <Ministries {...props} {...subProps} />}
    />
    <Route
      path="/articles"
      render={subProps => <Posts {...props} {...subProps} postType="Article" />}
    />
    <Route
      path="/prayer-letters"
      render={subProps => (
        <Posts {...props} {...subProps} postType="Prayer Letter" />
      )}
    />
    <Route
      path="/about"
      render={subProps => <About {...subProps} {...props} />}
    />
    <Route path="/languages" render={() => <Languages {...props} />} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
