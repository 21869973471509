import axios from 'axios';

const fetchVideoData = (playlistId, key) =>
  axios
    .get('https://www.googleapis.com/youtube/v3/playlistItems', {
      params: {
        part: 'snippet',
        playlistId,
        key,
        maxResults: 50
      }
    })
    .catch(err => err);

export default fetchVideoData;
