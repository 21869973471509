import React from 'react';
import { Segment, Container, Header, Image } from 'semantic-ui-react';

import { arrayOf, string, number } from 'prop-types';

import NavMenu from './NavMenu';

const Navigation = ({ terms, languageId }) => (
  <div>
    <Segment id="header" inverted>
      <div className="wrapper">
        <Container id="navigation">
          <Image src="https://www.twr360.net/pages/ccim/ccim-white.png" />
          <Header as="h1" content={terms[0]} inverted />
          <NavMenu terms={terms} languageId={languageId} />
        </Container>
      </div>
    </Segment>
  </div>
);

Navigation.propTypes = {
  terms: arrayOf(string).isRequired,
  languageId: number.isRequired
};

export default Navigation;
