import axios from 'axios';

const getLanguages = () =>
  axios
    .get('https://cms.twr360.net/api/languages')
    .then(languages => {
      const data = languages?.data?.data?.
        map(({ id, attributes }) => {
          if (attributes?.Ccim) {
            return {
              id,
              active: attributes?.Active,
              ccim: attributes?.Ccim,
              languageId: attributes?.LanguageId,
              localName: attributes?.LocalName,
              name: attributes?.Name
            }
          }
        })

      return data?.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
    })
    .catch(err => err);

export default getLanguages;
