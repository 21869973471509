import axios from 'axios';
const getPosts = () =>
  axios
    .get('https://cms.twr360.net/api/posts?populate=*')
    .then(response => {
      const posts = response?.data?.data?.map(({ id, attributes }) => {
        let Image = { id: attributes?.Image?.data?.id, ...attributes?.Image?.data?.attributes }
        let PDF = { id: attributes?.Image?.data?.id, ...attributes?.PDF?.data?.attributes }
        delete attributes.Image
        delete attributes.PDF
        return {
          id, ...attributes, Image, PDF
        }
      })
      return posts;
    })
    .catch(err => err);

export default getPosts;
