import React, { Component } from 'react';
import { Grid, Message, Loader } from 'semantic-ui-react';
import { string, arrayOf } from 'prop-types';

import getLanguage from './getLanguages';
import PageMenu from '../PageMenu';
import LanguageList from './LanguageList';

const searchLanguages = function searchLanguagesBySearchTerm(term, languages) {
  return languages.length > 0
    ? languages
      .filter(
        lang =>
          `${lang.name.toUpperCase()}${lang.localName.toUpperCase()}`.indexOf(
            term.toUpperCase()
          ) >= 0
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    : [];
};

class Languages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      languages: [],
      loading: true,
      error: false
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentWillMount() {
    getLanguage()
      .then(res => {
        if (!(res instanceof Error)) {
          this.setState({ languages: res, loading: false });
        } else {
          this.setState({ error: true, loading: false });
        }
      })
      .catch(() => this.setState({ error: true }));
  }

  handleSearchChange(e, data) {
    this.setState({ searchTerm: data.value });
  }

  render() {
    const { searchTerm, languages, loading, error } = this.state;
    const { pagePath, terms } = this.props;
    return (
      <>
        <PageMenu
          headerName={terms[2]}
          handleSearchChange={this.handleSearchChange}
          placeholder={terms[7]}
        />
        {!error ? (
          <Grid columns={3} as="ul" padded>
            <Loader active={loading} />
            <LanguageList
              languages={searchLanguages(searchTerm, languages)}
              pagePath={pagePath}
            />
          </Grid>
        ) : (
          <Message negative>{terms[8]}</Message>
        )}
      </>
    );
  }
}

Languages.propTypes = {
  pagePath: string.isRequired,
  terms: arrayOf(string).isRequired
};

export default Languages;
