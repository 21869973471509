import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const Name = () => (
  <Container text>
    <p>
      The appellation “Cross Currents International Ministries” embodies the
      stratagem of Spiritual Warfare, of Christian Renewal and of Church
      Mission.
    </p>
    <Header as="h3">Cross</Header>
    <p>
      It was at the cross where Jesus died that Satan became the defeated foe,
      “. . . that by [going through] death He might bring to naught and make of
      no effect him who had the power of death─that is, the devil” Hebrews 2:14
      Amplified Bible.
    </p>
    <p>
      It is at the cross the believer was identified with the Lord Jesus. God
      has pronounced death upon everything we received from Adam; death to
      self─to self-centred living and to reputation. The Lord Jesus made Himself
      “of no reputation.”
    </p>
    <p>
      It is because of the cross that CCIM understand the whole Church is One
      body and any self-promoting organization or denomination that lives in a
      water-tight compartment will ultimately quench the free-flow of the Holy
      Spirit. That is why CCIM considers itself to be a service oriented
      ministry.
    </p>
    <Header as="h3">Currents</Header>
    <p>
      “He brought waters out of the rock, and caused waters to run down like a
      river.” Psalm 78:15. When Moses smote the rock at Horeb there came forth
      water. Likewise after God the Father smote God the Son, for your sin and
      mine at Calvary, 50 days later on the day of Pentecost there flowed from
      heaven the life-giving power of the Holy Spirit. Consequently, we are
      taught in the New Testament that there is an eternity of difference
      between ‘digging a canal’ in the energy of the flesh and ‘following a
      river’ in the power of the Risen Lord Jesus Christ.
    </p>
    <Header as="h3">International</Header>
    <p>
      The marching order of the Risen Christ to His disciples was: “Go ye,
      therefore, and teach [evangelize] all nations, baptizing [enlist] in the
      name of the Father, and of the Son, and of the Holy Spirit, teaching
      [educate] them to observe all things whatsoever I have commanded you; and,
      lo, I am with you always [enablement], even unto the end of the world
      [exaltation]” Math 28:19. At His glorious and universal exhalation, the
      Lord Jesus will be acclaimed as “King of kings and Lord of lords.” Until
      then, we are to evangelize, enlist and educate all in the power of His
      enablement.
    </p>
    <Header as="h3">Ministries</Header>
    <p>
      The commission that Paul gave to his son in the faith, Timothy, was the
      same commission that Richard Bennett received from his father in Christ,
      Stephen Olford: “PREACH THE WORD.” CCIM encourages the teaching of God’s
      Word, book by book, chapter by chapter and verse by verse: “The entrance
      of Thy words bringeth light” Psalm 119: 130. In a spiritually deluded and
      dark world, CCIM is committed to “Preach God’s Word.” But we remember that
      the Word is unprofitable if it is “not mixed with faith.” So we realize
      that to teach the mind─in and of itself, is not sufficient! Unless God’s
      Word penetrates the mind to the will and there produces an act of faith
      and obedience, it is of no profit!
    </p>
    <p>
      Surely the need of this critical hour among most of us in our lukewarm
      church of today is a radical change of mind─toward ourselves and our love
      of comfort; or to sin which so easily besets us; and towards the world
      with its enticing glamour. That is why preaching to the will is imperative
      in the cause of Christ. Paul testified “Knowing therefore the terror of
      the Lord, we do persuade men” 2 Corinthians 5:11. In these days of social
      acceptability there is little persuasion in personal evangelism. To
      persuade is not to dominate the will as a gospel salesman, it is to
      encourage by every means possible the person or the congregation concerned
      to use their own will to bend before the person of our Lord Jesus Christ
      in the matters that concern them.
    </p>
  </Container>
);

export default Name;
