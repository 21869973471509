import axios from 'axios';

const getMinistries = (ministryIds, languageId) =>
  axios
    .get(`https://www.twr360.org/ministries/jsonFeed/language_id,${languageId}`)
    .then(res =>
      ministryIds
        .map(id =>
          res.data.ministries.find(ministry => ministry.id === id.toString())
        )
        .filter(ministry => ministry != null)
    )
    .catch(err => err);

export default getMinistries;
